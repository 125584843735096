import { Dispatch } from "redux";
import { IAdHocProject, IAdHocProjectResult, IHatchetAiCompanyProjectOcr, IProject } from "../../models/applicationState";
import AdHocOcrService from "../../services/adHocOcrService";
import { createPayloadAction, IPayloadAction } from "./actionCreators";
import { ActionTypes } from "./actionTypes";



export default interface IAdHocOcrActions {
    loadAdHocOcrProject(projectId: string): Promise<IProject>;
    getOcrProjectForPage(projectKey: string, page: number, sourceFile: string, assetName: string): Promise<IHatchetAiCompanyProjectOcr>;
    updateAdHocOcrProjectOcr(ocrs: IHatchetAiCompanyProjectOcr[]): Promise<void>;
    updateAdHocOcrProjectAssetLabel(projectId: string, projectName: string, assetName: string, labelData: string, updateLabelInContainer: boolean, label: string, runGenerateModel: boolean): Promise<void>;
    exportProjectLabelToSource(projectId: string, projectName: string, assetName: string): Promise<string>
    //updateDocumentOcr(projectId: string, projectName: string, assetName: string, ocr: string): Promise<void>;
}

export function loadAdHocOcrProject(projectId: string): (dispatch: Dispatch) => Promise<IProject> {
    return async (dispatch: Dispatch) => {
        const adHocService = new AdHocOcrService();
        const adhocProject = await adHocService.load(projectId);
        if (adhocProject) {
            dispatch(loadAdHocOcrProjectAction(adhocProject));
        }
        return Promise.resolve(null);
    }
}

export interface ILoadAdHocOcrProjectAction extends IPayloadAction<string, IAdHocProjectResult> {
    type: ActionTypes.AD_HOC_OCR_LOAD_PROJECT;
}

export const loadAdHocOcrProjectAction = createPayloadAction<ILoadAdHocOcrProjectAction>(ActionTypes.AD_HOC_OCR_LOAD_PROJECT);


export function getOcrProjectForPage(projectKey: string, page: number, sourceFile: string, assetName: string): (dispatch: Dispatch) => Promise<IHatchetAiCompanyProjectOcr> {
    return async (dispatch: Dispatch) => {
        const adHocOcrService = new AdHocOcrService();
        const ocr = await adHocOcrService.getOcrForPage(projectKey, page, sourceFile, assetName);
        return Promise.resolve(ocr);
    }
}

// export function updateDocumentOcr(projectId: string, projectName: string, assetName: string, ocr: string): (dispatch: Dispatch) => Promise<void> {
//     return async(dispatch: Dispatch) => {
//         const adHocOcrService = new AdHocOcrService();
//         const result = await adHocOcrService.updateDocumentOcr(projectId, projectName, assetName, ocr);
//         return Promise.resolve(null);
//     }
// }

export interface IUpdateAdHocOcrProjectOcr extends IPayloadAction<string, IHatchetAiCompanyProjectOcr[]> {
    type: ActionTypes.AD_HOC_OCR_UPDATE_PROJECT_OCR
}
export const updateAdHocOcrProjectOcrAction = createPayloadAction<IUpdateAdHocOcrProjectOcr>(ActionTypes.AD_HOC_OCR_UPDATE_PROJECT_OCR);
export function updateAdHocOcrProjectOcr(ocrs: IHatchetAiCompanyProjectOcr[]): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        dispatch(updateAdHocOcrProjectOcrAction(ocrs));
    }
}

export function updateAdHocOcrProjectAssetLabel(projectId: string, projectName: string, assetName: string, labelData: string, updateLabelInContainer: boolean, label: string, runGenerateModel: boolean) {
    return async (dispatch: Dispatch) => {
        const adHocOcrService = new AdHocOcrService();
        const result = await adHocOcrService.updateProjectAssetLabel(projectId, projectName, assetName, labelData, updateLabelInContainer, label, runGenerateModel);
        return Promise.resolve(result);
    }    
}

export function exportProjectLabelToSource(projectId: string, projectName: string, assetName: string) {
    return async (dispatch: Dispatch) => {
        const adHocOcrService = new AdHocOcrService();
        const result = await adHocOcrService.exportProjectLabelToSource(projectId, projectName, assetName);
        return Promise.resolve(result);
    }    
}
