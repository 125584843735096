import { Dispatch } from "redux";
import { IAutoLabelModel, IAutoLabelState, IGetAutoLabelResult, IGetDocumentServiceModel } from "../../models/applicationState";
import AutoLabelModelManagementService from "../../services/autoLabelModelManagementService";
import { ActionTypes } from "./actionTypes";
import { createPayloadAction, IPayloadAction } from "./actionCreators";


export default interface IAutoLabelModelManagementActions {
    get(): Promise<void>;
    getById(modelId: string): Promise<IAutoLabelModel>;
    getDocumentServicesByModelId(modelId: string): Promise<IGetDocumentServiceModel[]>;
    generateModel(documentSeviceId: string): Promise<boolean>;
}

export function get(): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        console.log('getAutoLabels');        

        const autoLabelService = new AutoLabelModelManagementService();
        const autoLabels = await autoLabelService.get();
        dispatch(getAutoLabelsAction(autoLabels));        
    }
} 

export function getById(modelId: string): (dispatch: Dispatch) => Promise<IAutoLabelModel> {
    return async (dispatch: Dispatch) => {
        const autoLabelService = new AutoLabelModelManagementService();
        const autoLabel = await autoLabelService.getById(modelId);
        return Promise.resolve(autoLabel);
    }
}

export function getDocumentServicesByModelId(modelId: string): (dispatch: Dispatch) => Promise<IGetDocumentServiceModel[]> { 
    return async (dispatch: Dispatch) => {
        const autoLabelService = new AutoLabelModelManagementService();
        const docServices = await autoLabelService.getDocumentServicesByModelId(modelId);
        return Promise.resolve(docServices);
    }
}

export function generateModel(documentSeviceId: string): (dispatch: Dispatch) => Promise<boolean> {
    return async (dispatch: Dispatch) => {
        const autoLabelService = new AutoLabelModelManagementService();
        const result = await autoLabelService.generateModel(documentSeviceId);
        return Promise.resolve(result);
    }
}


export interface IGetAutoLabelAction extends IPayloadAction<string, IAutoLabelState> {
    type: ActionTypes.GET_AUTOLABELS;
}

export const getAutoLabelsAction = createPayloadAction<IGetAutoLabelAction>(ActionTypes.GET_AUTOLABELS);